.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  margin: 1.5% 30% 3% 30%;
  gap: 2rem;
}

.emoji {
  font-size: 1.5rem;
}

.achievement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.achievementsHeading {
  font-size: 1.75rem;
  font-weight: 500;
  font-family: "Fira Code";
  color: #6adac4;
  margin: 1rem 0;
}

.achievementName {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Fira Code";
  color: #868aa4;
}

.achievementsList ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

@media screen and (max-width: 820px) {
  .container {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0;
    border: none;
    padding: 6% 15%;
  }
}

@media screen and (max-width: 430px) {
  .container {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5% 0% 6% 0%;
  }
  .achievementsHeading {
    font-size: 1.25rem;
  }
  .achievementName {
    font-size: 0.75rem;
    font-weight: 500;
  }
}
