.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  margin: 1.5% 30%;
  gap: 0.5rem;
  /* border-bottom: #868aa4 1px solid; */
}

.projectHeading {
  font-size: 1.75rem;
  font-weight: 500;
  font-family: "Fira Code";
  color: #6adac4;
  margin: 1rem 0;
}

.projectList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (max-width: 820px) {
  .container {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0;
    border: none;
    padding: 6% 15%;
  }
}

@media screen and (max-width: 430px) {
  .container {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .projectHeading {
    font-size: 1.25rem;
  }
}
