.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
  margin: 1.5% 30%;
  gap: 1rem;
}

.aboutHeading {
  font-size: 1.75rem;
  font-weight: 500;
  font-family: "Fira Code";
  color: #6adac4;
  margin: 1rem 0;
}

.aboutDescription {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Fira Code";
  color: #868aa4;
  margin: 1rem 0;
  line-height: 1.5;
}

.aboutDescription ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 820px) {
    .container {
      width: 70%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 0;
      border: none;
      padding: 6% 15%;
    }
  }

@media screen and (max-width: 430px) {
  .container {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5% 15%;
    padding: 0;
  }

  .aboutText {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .aboutImage img {
    height: 100px;
    width: 100px;
    border-radius: 10px;
  }
  .aboutHeading {
    width: 100%;
    font-size: 1.25rem;
    text-align: center;
  }
  .aboutDescription {
    font-size: 0.75rem;
  }
}
