.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  margin: 1.5% 30%;
  gap: 0.5rem;
  /* border-bottom: #868aa4 1px solid; */
}

.experienceCard {
  /* border-bottom: #868aa4 1px solid; */
}

.experienceHeading {
  font-size: 1.75rem;
  font-weight: 500;
  font-family: "Fira Code";
  color: #6adac4;
  margin: 1rem 0;
}

.experienceCardHeading {
  font-size: 1.25rem;
  font-weight: 500;
  font-family: "Fira Code";
  color: #6adac4;
  margin: 1rem 0;
}

.experienceCardSubHeading {
  font-size: 1.25rem;
  font-weight: 700;
  font-family: "Fira Code";
  color: #868aa4;
  margin: 1rem 0;
}

.experienceCardDuration {
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "Fira Code";
  color: #868aa4;
  margin: 1rem 0;
}

@media screen and (max-width: 820px) {
  .container {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0;
    border: none;
    padding: 6% 15%;
  }
}

@media screen and (max-width: 430px) {
  .container {
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 1.5% 0;
  }
  .experienceCard {
    width: 80%;
  }
  .experienceHeading {
    width: 100%;
    font-size: 1.25rem;
    text-align: center;
  }
  .experienceCardHeading {
    font-size: 1rem;
  }
  .experienceCardSubHeading {
    font-size: 1rem;
  }
  .experienceCardDuration {
    font-size: 0.75rem;
  }
}
