@font-face {
  font-family: "Fira Code";
  src: url(./fonts/FiraCode/FiraCodeNerdFont-Regular.ttf);
}

body {
  background: #1a1b26;
  font-family: "Fira Code";
  color: #868aa4;
}

/*Scroll bar*/
::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  background: #1a1b26;
}

::-webkit-scrollbar-thumb {
  background: #6adac4;
}

::-webkit-scrollbar-thumb:hover {
  background: #3aa6b5;
}

a {
  text-decoration: none;
  color: #6adac4;
}
